<template>
  <div v-show="isShow">
    <div class="boss">
      <div class="bgc">
        <div class="center">
          <van-grid
            :column-num="2"
            :border="false"
            :gutter="8"
            v-if="havePig == 0"
          >
            <van-grid-item
              v-for="item in list"
              :key="item.id"
              @click="goDetail(item.id, 0)"
            >
              <div class="content">
                <div class="info">
                  <img :src="item.image" alt="" />
                  <div class="text">
                    <p>猪龄：{{ item.month }} 月</p>
                    <p>体重：{{ item.weight }} KG</p>
                  </div>
                </div>
                <div class="bottom">
                  <div
                    v-if="is_reservation == '0'"
                    class="btn"
                    @click.stop="
                      goBuy(item.id, item.price, item.total, item.earnest_money)
                    "
                  >
                    <p>{{ item.price }}</p>
                    <img src="../../assets/img/jb.png" alt="" />
                  </div>
                  <div
                    v-if="is_reservation == '1'"
                    class="btns"
                    @click.stop="
                      goBuy(item.id, item.price, item.total, item.earnest_money)
                    "
                  ></div>
                </div>
                <div class="she" v-if="item.pigpen == '普通猪舍'">
                  <img src="../../assets/img/ordinary.png" alt="" />
                </div>
                <div class="she" v-else>
                  <img src="../../assets/img/luxury.png" alt="" />
                </div>
              </div>
            </van-grid-item>
          </van-grid>
          <div v-if="havePig == 1 && !isReserve" class="nodata">
            <img src="../../assets/img/tu.png" alt="" />
            <div class="reserve">
              <p>乡村猪崽有限，已售罄！</p>
              <p>赶紧预约吧！猪崽大约3~7天上新！</p>
            </div>
            <van-grid :column-num="2" :border="false" :gutter="8">
              <van-grid-item
                v-for="item in list"
                :key="item.id"
                @click="goDetail(item.id, 1)"
              >
                <div class="content">
                  <div class="info">
                    <img :src="item.image" alt="" />
                    <div class="text">
                      <p>猪龄：{{ item.month }} 月</p>
                      <p>体重：{{ item.weight }} KG</p>
                    </div>
                  </div>
                  <div class="bottom">
                    <!-- @click.stop="gorReserve()" -->
                    <div class="btnyu"></div>
                  </div>
                  <div class="she" v-if="item.pigpen == '普通猪舍'">
                    <img src="../../assets/img/ordinary.png" alt="" />
                  </div>
                  <div class="she" v-else>
                    <img src="../../assets/img/luxury.png" alt="" />
                  </div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>
          <div v-if="isReserve" class="sucess">
            <img src="../../assets/img/non.png" alt="" />
            <p>您已预约成功，当前暂无上新猪崽~</p>
          </div>
        </div>
        <div class="close" @click="pageClose">
          <img src="../../assets/img/close.png" alt="" />
        </div>
      </div>
      <gold :goldShow="goldShow" @goldUpdate="goldUpdate"></gold>
      <yu-yue
        :popshow="popshow"
        @closePop="closePop"
        :yuMoney="yuMoney"
        @adoption="adoption"
      ></yu-yue>
    </div>
  </div>
</template>

<script>
import { WebView } from '../../common/app'
import { getList, reservation } from '../../common/api'
import gold from '../../components/gold.vue'
import yuYue from '../../components/yuyue.vue'
export default {
  components: {
    gold,
    yuYue,
  },
  data() {
    return {
      list: [], // 猪崽列表
      popshow: false, //预约弹窗
      isReserve: false, // 是否预约
      is_reservation: '', // 是否预约 0 无  1 有
      yuMoney: 0, // 预约金
      goldShow: false, //金币不足弹窗
      havePig: '', // 是否有猪崽 0无 1有
      isShow: false,
    }
  },
  computed: {},
  watch: {},

  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    console.log('id', this.$store.state.access_id)
    WebView.getAccessId = this.getAccessId

    this.getAccessId()
  },
  methods: {
    getAccessId(accessId = this.$store.state.access_id) {
      console.log(accessId)
      this.$store.commit('SET_ACCESS_ID', accessId)
      this.getLists()
    },
    //获取列表
    async getLists() {
      let res = await getList({ access_id: this.$store.state.access_id })
      if (res.data.code == '40004') {
        this.list = res.data.data
      }
      if (res.data.code == '90001') {
        this.isReserve = true
      }
      if (res.data.code == 200) {
        this.list = res.data.data.list
        this.havePig = res.data.data.no_pig
        this.yuMoney = res.data.data.deposit_money
        this.is_reservation = res.data.data.is_reservation
      }
    },
    // 去预约
    gorReserve() {
      this.popshow = true
    },
    // 去详情 type 0 正常详情   1 预约详情
    goDetail(item, type) {
      this.$store.commit('SET_P_ID', item)
      this.$router.push({
        path: '/detail',
        query: {
          type: type,
        },
      })
    },
    // 去购买
    goBuy(pid, price, total, money) {
      this.$store.commit('SET_P_ID', pid)
      this.$router.push({
        path: '/protocol',
        query: {
          price: price,
          total: total,
          money: money,
        },
      })
    },
    pageClose() {
      window.location.href = 'uniwebview://closepage?test=closepage'
    },
    // 关闭预约弹窗
    closePop(val) {
      console.log(val, 'val')
      this.popshow = val
    },
    goldUpdate: (function () {
      let timer = null
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow
        }, 300)
      }
    })(),
    adoption: (function () {
      let timer = null
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          reservation({ access_id: this.$store.state.access_id }).then(
            (res) => {
              if (res.data.code == '80001') {
                //金币不足
                this.popshow = !this.popshow
                this.goldShow = true
              }
              if (res.data.code == 200) {
                this.popshow = !this.popshow
                this.getLists()
              }
            },
          )
        }, 300)
      }
    })(),
  },
}
</script>

<style lang="scss" scoped>
.boss {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#000, 0.1);
  .bgc {
    width: 100%;
    height: 14.5rem;
    background: url('../../assets/img/lingyang.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    position: relative;
    .center {
      width: 7.8rem;
      height: 10.1rem;
      display: flex;
      flex-wrap: wrap;
      margin-top: 2.97rem;
      margin-left: 0.04rem;
      overflow: hidden;
      overflow-y: scroll;
      .van-grid {
        .van-grid-item {
          height: 4.8rem;
        }
        /deep/ .van-grid-item__content--center {
          background: #945614;
        }
      }
    }
  }
  .content {
    width: 3.3rem;
    // margin: 0 0.2rem;
    position: relative;
    .she {
      position: absolute;
      bottom: 2.32rem;
      left: 1.36rem;
      img {
        width: 2rem;
        height: auto;
      }
    }
    .info {
      position: relative;
      display: flex;
      justify-content: center;
      img {
        width: 3rem;
        height: 3rem;
        object-fit: contain;
      }
      .text {
        position: absolute;
        top: -0.2rem;
        left: 0rem;
        color: #fff;
        z-index: 999;
        p {
          background: rgba(#000, 0.2);
          box-shadow: 0 0 0.1rem #ccc;
          margin-bottom: 0.12rem;
          border-radius: 0.06rem;
          padding: 0.02rem 0.1rem;
        }
      }
      .imgs {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 3rem;
          height: auto;
        }
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.14rem;
      .btnyu {
        width: 3.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
        font-size: 0.5rem;
        color: #fff;
        background: url('../../assets/img/yu.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .btn {
        width: 3.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
        font-size: 0.5rem;
        color: #fff;
        background: url('../../assets/img/ling.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        p {
          margin-right: 0.3rem;
          height: 0.5rem;
          line-height: 0.5rem;
        }
        img {
          width: 0.55rem;
          height: 0.55rem;
          position: absolute;
          right: 0.3rem;
        }
      }
      .btns {
        width: 3.1rem;
        height: 1.1rem;
        line-height: 1.1rem;
        font-size: 0.5rem;
        color: #fff;
        background: url('../../assets/img/wei.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }
  }
}
.close {
  position: absolute;
  top: 1.1rem;
  right: 0.3rem;
  img {
    width: 1rem;
    height: 1rem;
    object-fit: contain;
  }
}
.sucess {
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 4.5rem;
    height: auto;
  }
  p {
    margin: 0.2rem 0;
    color: #945614;
    font-size: 0.34rem;
  }
}
.nodata {
  width: 100%;
  height: 10.1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    width: 4rem;
    height: 3rem;
  }
  .reserve {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.2rem 0 0.45rem;
    p {
      margin: 0.1rem 0 0 0.25rem;
      font-size: 0.3rem;
      font-weight: 400;
      color: #8f5213;
    }
    img {
      width: 4rem;
      height: auto;
      margin-left: 0.09rem;
    }
  }
}
.van-popup {
  background: none;
}
</style>
